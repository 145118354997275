.Notifications {
  @apply flex flex-col items-start gap-4 rounded-2xl bg-white shadow-md shadow-shadow-700 lg:rounded-3xl;
  @apply max-h-[70vh] w-[94vw] max-w-[400px] p-2 sm:w-96 lg:max-h-[60vh] lg:p-4;
  @apply absolute right-0 top-11 transform md:right-1 lg:right-0 lg:top-16;

  h3 {
    @apply text-xl font-bold text-secondary-dark-grey-900;
  }

  &__wrapper {
    @apply flex w-full flex-col gap-4 overflow-auto pr-4;

    span {
      @apply text-sm font-semibold text-secondary-dark-grey-400;
    }
  }
}
